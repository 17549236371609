import {
  useRef,
  useEffect,
  useLayoutEffect,
  useState,
  useCallback,
  useMemo,
  RefObject,
} from 'react';
import { useViewport } from '../util/useViewport';
import { IGatsbyImageData } from 'gatsby-plugin-image';

/**
 * Custom hook that uses either useLayoutEffect or useEffect based on the environment (client-side or server-side).
 * https://usehooks-ts.com/react-hook/use-isomorphic-layout-effect
 */
export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

/**
 * Custom hook that creates an interval that invokes a callback function at a specified delay.
 * https://usehooks-ts.com/react-hook/use-interval
 */
export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (delay === null) {
      return;
    }

    const id = setInterval(() => {
      savedCallback.current();
    }, delay);

    return () => {
      clearInterval(id);
    };
  }, [delay]);
}

/**
 * Custom hook that throttles an often called function on a delay for performance.
 * @param callback - The function to throttle.
 * @param delay - The delay in milliseconds between calls.
 * @returns An auto-throttling function.
 */
export function useThrottle<Args extends unknown[]>(
  callback: (...args: Args) => void,
  delay = 100
) {
  const lastCall = useRef(0);

  return useCallback(
    (...args: Args) => {
      const now = new Date().getTime();
      if (now - lastCall.current >= delay) {
        lastCall.current = now;
        callback(...args);
      }
    },
    [callback, delay]
  );
}

/**
 * Custom hook that provides the logic and state management for a slideshow component.
 * @param slideCount - The total number of slides in the slideshow.
 * @param delay - The delay in milliseconds between automatically advancing slides.
 * @param slideShowRef - Ref to the container div that contains the tarck and the slides.
 * @returns An object containing:
 * - `currentSlide`: The index of the currently active slide.
 * - `setCurrentSlide`: A function to set a specific slide as active and handle autoplay.
 */
export function useSlideshow(
  slideCount: number,
  delay: number,
  slideShowRef: RefObject<HTMLDivElement | null>
) {
  const isUserScroll = useRef(true);
  const [playing, setPlaying] = useState(true);
  const [slideIndex, setSlideIndex] = useState(0);

  // scroll to slide programmatically
  const scrollToSlide = useCallback(
    (index: number) => {
      if (!slideShowRef.current) return;
      const slideWidth = slideShowRef.current.clientWidth;
      slideShowRef.current.scrollLeft = index * slideWidth;
      isUserScroll.current = false;
    },
    [slideShowRef]
  );

  // change slide number if slide is in view
  const handleScroll = useThrottle(
    useCallback(() => {
      if (!slideShowRef.current) return;
      const slideWidth = slideShowRef.current.clientWidth;
      const scrollLeft = slideShowRef.current.scrollLeft;
      const newSlideIndex = Math.round(scrollLeft / slideWidth);
      setSlideIndex(newSlideIndex);

      // Reset the flag for the next scroll event
      if (!isUserScroll.current) {
        isUserScroll.current = true;
        return;
      }

      // If the scroll is user-initiated, stop autoplay
      setPlaying(false);
    }, [slideShowRef])
  );

  // change slide when nav is clicked
  const handleNav = useCallback(
    (index: number, play = false) => {
      setPlaying(play);
      setSlideIndex(index);
      scrollToSlide(index);
    },
    [scrollToSlide]
  );

  // change slide on an interval
  useInterval(() => {
    if (playing) {
      const newIndex = (slideIndex + 1) % slideCount;
      setSlideIndex(newIndex);
      scrollToSlide(newIndex);
    }
  }, delay);

  // update current slide number when a slide is scrolled horizontally to.
  useEffect(() => {
    const slideShowElement = slideShowRef.current;
    if (slideShowElement) {
      slideShowElement.addEventListener('scroll', handleScroll);

      return () => {
        slideShowElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll, slideShowRef]);

  return {
    currentSlide: slideIndex,
    setCurrentSlide: handleNav,
  };
}

/**
 * Custom hook that handles grouping of items on an interval.
 * Slideshow like, but returns a number of items at a time.
 *
 * @param items - the array to slice on an interval.
 * @param interval the interval to send each slice.
 * @param desktopCount - how many items in a slice for desktop.
 * @param tabletCount - how many items in a slice for tablet.
 * @param mobileCount - how many items in a slice for mobile.
 * @returns an updated array slice from the items array on an interval.
 */
export function useItemGroups(
  items: (string | IGatsbyImageData)[],
  interval = 3000,
  desktopCount = 6,
  tabletCount = 3,
  mobileCount = 2
) {
  const { isTablet, isDesktop } = useViewport();
  const [groupIndex, setGroupIndex] = useState(0);

  const groupSize = isDesktop
    ? desktopCount
    : isTablet
    ? tabletCount
    : mobileCount;

  const itemsWithIds = useMemo(
    () =>
      items.map((item, index) => ({
        id: index,
        src: item,
      })),
    [items]
  );

  const numberOfGroups = Math.ceil(itemsWithIds.length / groupSize);

  useInterval(() => {
    setGroupIndex((prevIndex) => (prevIndex + 1) % numberOfGroups);
  }, interval);

  const currentItems = itemsWithIds.slice(
    groupIndex * groupSize,
    (groupIndex + 1) * groupSize
  );

  return currentItems;
}
