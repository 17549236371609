import React from 'react';
import { Text } from './Eyebrow.styles';

export interface EyebrowProps {
  text?: string;
}

const Eyebrow: React.FC<EyebrowProps> = ({ text }) => {
  return <Text>{text}</Text>;
};

export default Eyebrow;
