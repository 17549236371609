import styled from 'styled-components';
import { breakpointUp } from '../../../styles/mediaUtils';
import { typeScale } from '../../../styles/newTypography';
import { spacing } from '../../../styles/newThemeVariables.styles';

export const Header = styled.header`
  grid-column: span 2;
  display: flex;
  align-items: end;
  padding-top: ${spacing.space32};
  padding-bottom: ${spacing.space32};
  min-height: 18.75rem;

  @media ${breakpointUp.tablet} {
    grid-column: span 8;
    padding-top: ${spacing.space72};
    padding-bottom: ${spacing.space72};
    min-height: 25rem;
  }

  @media ${breakpointUp.desktop} {
    grid-column: span 6;
    min-height: 28.125rem;
  }

  @media ${breakpointUp.desktopLarge} {
    grid-column: span 6;
    min-height: 35rem;
  }
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.textPrimary};
  text-wrap: balance;
  margin: 0;
  ${typeScale.mobile.body.lg};

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.body.xxl};
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.body.xxxl};
  }

  @media ${breakpointUp.desktopLarge} {
    ${typeScale.desktop.body.xxxxl};
  }

  .xl & {
    ${typeScale.mobile.body.xl};

    @media ${breakpointUp.tablet} {
      ${typeScale.tablet.body.xxxl};
    }

    @media ${breakpointUp.desktop} {
      ${typeScale.desktop.body.xxxxl};
    }

    @media ${breakpointUp.desktopLarge} {
      ${typeScale.desktop.body.fivexl};
    }
  }
`;
