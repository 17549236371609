import React from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import styled, { ThemeProvider } from 'styled-components';
import AssetImage from '../../components/AssetImage';
import LinkButton from '../../components/Button/LinkButton';
import defaultTheme, { darkTheme } from '../../../styles/themeVariables';
import { breakpointUp } from '../../../styles/mediaUtils';
import { textSizes } from '../../../styles/typography';
import { Layout } from '../../layout/Layout';
import { Grid } from '../../layout/Grid';

export type BrandModuleProps = {
  background_image: IGatsbyImageData | string;
  background_alt: string;
  button_link: string;
  button_text: string;
  light_theme: boolean;
  tagline: string;
  title: string;
};

const StylSection = styled.section`
  height: 41.25rem;
  padding: 0 !important;
  position: relative;
  display: flex;
  align-items: end;

  @media ${breakpointUp.sm} {
    height: 50rem;
  }
`;

const StylBgImage = styled(AssetImage)`
  position: absolute;
  z-index: -1;
  height: 100%;

  & > img {
    height: 100%;
    object-fit: cover;
  }
`;

const StylContent = styled.div`
  color: ${({ theme }) => theme.text1};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding-bottom: 2rem;
  grid-column: 1 / -1;

  @media ${breakpointUp.sm} {
    padding-bottom: 2rem;
  }

  @media ${breakpointUp.lg} {
    padding-bottom: 3rem;
    grid-column: 1 / span 9;
  }

  h4 {
    ${textSizes.xs};
    margin-bottom: 1.125rem;

    @media ${breakpointUp.sm} {
      margin-bottom: 0.75rem;
    }

    @media ${breakpointUp.lg} {
      margin-bottom: 1.5rem;
    }
  }

  h2 {
    ${textSizes.xl};
    max-width: 62rem;
    margin-bottom: 1.125rem;

    @media ${breakpointUp.sm} {
      ${textSizes.xxl};
      margin-bottom: 2.5rem;
    }

    @media ${breakpointUp.lg} {
      ${textSizes.xxxl};
      margin-bottom: 2rem;
      white-space: break-spaces;
    }
  }
`;

export const BrandModule = ({
  button_link,
  button_text,
  tagline,
  light_theme,
  background_image,
  background_alt,
  title,
}: BrandModuleProps) => {
  return (
    <ThemeProvider theme={light_theme ? defaultTheme : darkTheme}>
      <StylSection>
        {background_image && (
          <StylBgImage image={background_image} alt={background_alt} />
        )}
        <Layout>
          <Grid>
            <StylContent>
              {tagline && <h4>{tagline}</h4>}
              {title && <h2>{title}</h2>}
              {button_text && (
                <div>
                  <LinkButton
                    href={button_link ?? '#'}
                    btnsize="sm"
                    variant="ghost"
                  >
                    {button_text}
                  </LinkButton>
                </div>
              )}
            </StylContent>
          </Grid>
        </Layout>
      </StylSection>
    </ThemeProvider>
  );
};

export default BrandModule;
