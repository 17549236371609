import React, { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import defaultTheme, { darkTheme } from '../../../styles/themeVariables';
import {
  SectionWrapper,
  Title,
  TitleRow,
  RelatedPostsWrapper,
} from './CaseStudyRelatedPosts.styles';
import { CaseStudyThumbnailProps } from '../../blocks/CaseStudyThumbnail';
import { CaseStudyRelatedPost } from './CaseStudyRelatedPost';
import { Layout } from '../../layout/Layout';
import LinkButton from '../../components/Button/LinkButton';

export type CaseStudyRelatedPostsProps = {
  posts: CaseStudyThumbnailProps[];
  light?: boolean;
};

const CaseStudyRelatedPosts: FC<CaseStudyRelatedPostsProps> = ({
  light,
  posts,
}) => {
  return (
    <ThemeProvider theme={light ? defaultTheme : darkTheme}>
      <SectionWrapper>
        <Layout>
          <TitleRow>
            <Title>RELATED WORK</Title>
            <LinkButton
              btnsize={'sm'}
              variant={'ghost'}
              dark={true}
              href={`/work/`}
            >
              See more work
            </LinkButton>
          </TitleRow>
          <RelatedPostsWrapper>
            {posts.map((post) => (
              <CaseStudyRelatedPost
                key={post.id}
                link={post.link}
                client={post.client}
                thumbnail_alt={post.thumbnail_alt}
                thumbnail_sm={post.thumbnail_sm}
                thumbnail_lg={post.thumbnail_lg}
                title={post.title}
                tags={post.tags}
                categories={post.categories}
                id={post.id}
              />
            ))}
          </RelatedPostsWrapper>
        </Layout>
      </SectionWrapper>
    </ThemeProvider>
  );
};

export default CaseStudyRelatedPosts;
