import styled from 'styled-components';
import { breakpointUp } from '../../../styles/mediaUtils';
import { spacing } from '../../../styles/newThemeVariables.styles';
import { SpacerProps } from '.';

export const sizes = {
  xs: spacing.space72,
  sm: spacing.space160,
  md: spacing.space168,
  lg: spacing.space250,
};

export const SpacerElement = styled.div<SpacerProps>`
  height: ${({ mobileUp }) => (mobileUp ? sizes[mobileUp] : sizes.sm)};

  @media ${breakpointUp.tablet} {
    height: ${({ tabletUp }) => (tabletUp ? sizes[tabletUp] : sizes.md)};
  }

  @media ${breakpointUp.desktop} {
    height: ${({ desktopUp }) => (desktopUp ? sizes[desktopUp] : sizes.lg)};
  }
`;
