import styled, { css } from 'styled-components';
import React, { FC } from 'react';
import { CategoryType } from '../../old/storyblok/categories-context';
import { TagType } from '../../old/storyblok/tags-context';
import CMSLink from './CMSLink';

const SectorsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.5rem;
`;

export const ListItem = styled.li<{
  underlineColor?: string;
  fontColor?: string;
}>`
  ${({ underlineColor, fontColor }) => css`
    padding-bottom: 0.125rem;
    border-bottom: 0.125rem solid ${underlineColor};
    color: ${fontColor};
  `}
`;

const Link = styled(CMSLink)`
  cursor: pointer;
`;

export type Props = {
  tags: TagType[];
  categories?: CategoryType[];
  className?: string;
  asLinks: boolean;
  underlineColor?: string;
  fontColor?: string;
  dataTestId?: string;
};

const Sectors: FC<Props> = ({
  tags,
  categories,
  className,
  asLinks,
  underlineColor,
  fontColor,
  dataTestId,
}) => {
  return (
    <SectorsList className={className}>
      {tags &&
        tags.map((tag) => (
          <ListItem
            key={tag.uuid}
            underlineColor={underlineColor}
            fontColor={fontColor}
            data-testid={dataTestId}
          >
            {asLinks ? <Link to={tag.full_slug}>{tag.title}</Link> : tag.title}
          </ListItem>
        ))}
      {categories &&
        categories.map((category) => {
          const link = category.full_slug.replace(`${category.slug}/`, '');
          return (
            <ListItem
              key={category.uuid}
              underlineColor={underlineColor}
              fontColor={fontColor}
            >
              {asLinks ? (
                <Link to={link}>{category.title}</Link>
              ) : (
                category.title
              )}
            </ListItem>
          );
        })}
    </SectorsList>
  );
};

export default Sectors;
