import React, { FC } from 'react';
import { useContactForm } from './useContactForm';
import {
  FormSection,
  Form,
  TextBox,
  Tagline,
  Title,
  Subtitle,
} from './ContactFormModule.styles';
import { FormInput } from '../../components/FormInput';
import { FormTextArea } from '../../components/FormTextArea';
import { isProduction } from '../../../util/isProduction';
import TickIcon from '../../../icons/TickIcon';
import { Layout } from '../../layout/Layout';
import { Grid } from '../../layout/Grid';
import { CallToActionLinkButton } from '../../components/CallToActionLink';

export interface ContactFormModuleProps {
  form_data: string;
  tagline: string;
  title: string;
  subtitle: string;
  light_theme?: boolean;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  disabled?: boolean;
  submitted?: boolean;
  hasError?: boolean;
}

export const ContactFormModule: FC<ContactFormModuleProps> = ({
  form_data,
  tagline,
  title,
  subtitle,
  onSubmit,
  disabled,
  submitted,
  hasError,
}) => {
  const { handleSubmit, updateFormData, formErrors, requestStatus } =
    useContactForm({
      formKey: form_data,
    });

  return (
    <FormSection>
      <Layout>
        <Grid>
          <Tagline>{tagline}</Tagline>

          <TextBox>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
          </TextBox>

          <Form onSubmit={onSubmit ?? handleSubmit}>
            <FormInput
              placeholder="Full name"
              onChange={(event) =>
                updateFormData((prevState) => ({
                  ...prevState,
                  fullName: event.target.value,
                }))
              }
            />
            <FormInput
              placeholder="Job title"
              onChange={(event) =>
                updateFormData((prevState) => ({
                  ...prevState,
                  jobTitle: event.target.value,
                }))
              }
            />
            <FormInput
              placeholder="Company name"
              onChange={(event) =>
                updateFormData((prevState) => ({
                  ...prevState,
                  companyName: event.target.value,
                }))
              }
            />
            <FormInput
              placeholder="Email Address*"
              isError={hasError ?? !!formErrors.email}
              errorMessage={formErrors.email}
              onChange={(event) =>
                updateFormData((prevState) => ({
                  ...prevState,
                  email: event.target.value,
                }))
              }
            />
            <FormTextArea
              placeholder="Tell us about your project (optional)"
              isError={hasError ?? !!formErrors.message}
              errorMessage={formErrors.message}
              onChange={(event) =>
                updateFormData((prevState) => ({
                  ...prevState,
                  message: event.target.value,
                }))
              }
            />
            <CallToActionLinkButton
              size="md"
              type="submit"
              disabled={disabled ?? (!isProduction || requestStatus.isSuccess)}
              data-id={
                requestStatus.isSuccess
                  ? 'contact-form-sent'
                  : 'contact-form-submit'
              }
            >
              {submitted ?? requestStatus.isSuccess ? (
                <>
                  Sent <TickIcon />
                </>
              ) : (
                'Send'
              )}
            </CallToActionLinkButton>
          </Form>
        </Grid>
      </Layout>
    </FormSection>
  );
};

export default ContactFormModule;
