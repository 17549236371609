import styled, { css } from 'styled-components';
import StoryblokAssetImage from '../../components/AssetImage';

export type ImageStyleProps = {
  type:
    | 'inline'
    | 'inline-top'
    | 'inline-bottom'
    | 'overhang-left'
    | 'overhang-right';
};

export const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`;

export const StyledImage = styled(StoryblokAssetImage)<ImageStyleProps>`
  ${({ type }) =>
    ({
      inline: css`
        img {
          width: 100%;
        }
        align-self: center;
      `,
      'inline-bottom': css`
        img {
          width: 100%;
        }
        align-self: flex-end;
      `,
      'inline-top': css`
        img {
          width: 100%;
        }
        align-self: flex-start;
      `,
      'overhang-left': css`
        position: absolute;
        top: 0;
        right: 0;
      `,
      'overhang-right': css`
        position: absolute;
        top: 0;
        left: 0;
      `,
    }[type])}
`;
