import styled from 'styled-components';
import { breakpointUp } from '../../../styles/mediaUtils';
import { StyledWrapper } from '../ArticleContentModule/ArticleContentModule.styles';

export const Module = styled(StyledWrapper)`
  grid-column: 1 / -1;
  padding-top: 3rem;
  padding-bottom: 3rem;

  @media ${breakpointUp.md} {
    grid-column: 3 / span 6;
  }
`;
