import React, { FC, useMemo } from 'react';

import { HideOnDesktop, ShowOnDesktop } from './InlineFilterModule.styles';
import InlineFilterMenu from './InlineFilterMenu';
import InlineFilterList from './InlineFilterList';
import PageHeaderModule from '../PageHeaderModule';

import { useTags } from '../../../old/storyblok/tags-context';
import { useCategories } from '../../../old/storyblok/categories-context';
import { useLocation } from '@gatsbyjs/reach-router';

interface InlineFilterModuleProps {
  headerTitle?: string;
  resetLabel?: string;
  resetLabelMobile?: string;
  resetFilterUrl?: string;
}

const InlineFilterModule: FC<InlineFilterModuleProps> = ({
  headerTitle,
  resetLabel = 'All',
  resetLabelMobile = 'Filter',
  resetFilterUrl,
}) => {
  const allTags = useTags();
  const allCategories = useCategories();
  const location = useLocation();

  const selectedFilter = useMemo(() => {
    const selectedTag = allTags.find((tag) =>
      location.pathname.includes(`tag/${tag.slug}`)
    );
    const selectedCategory = allCategories.find((category) =>
      location.pathname.includes(`category/${category.slug}`)
    );

    return selectedTag || selectedCategory;
  }, [allCategories, allTags, location]);

  return (
    <>
      <HideOnDesktop>
        {headerTitle && <PageHeaderModule title={headerTitle} />}
        <InlineFilterMenu
          label={selectedFilter?.title || resetLabelMobile}
          categoryList={allCategories}
          tagList={allTags}
          resetFilterUrl={!!selectedFilter?.uuid ? resetFilterUrl : undefined}
        />
      </HideOnDesktop>
      <ShowOnDesktop>
        <InlineFilterList
          categoryList={allCategories}
          tagList={allTags}
          resetLabel={resetLabel}
          resetFilterUrl={resetFilterUrl}
        />
      </ShowOnDesktop>
    </>
  );
};

export default InlineFilterModule;
