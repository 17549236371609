import { IGatsbyImageData } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import { AuthorType } from '../../../old/storyblok/authors-context';
import { CategoryType } from '../../../old/storyblok/categories-context';
import { TagType } from '../../../old/storyblok/tags-context';
import {
  ThumbnailWrapper,
  LinkWrapper,
  ImgContainer,
  ThumbnailImg,
  TextWrapper,
  BylineWrapper,
  PageNumber,
  Title,
  Description,
  CategorySection,
  ImgWrapper,
} from './ReportThumbnail.styles';
import AuthorByline from '../../components/AuthorByline';

export type ReportThumbnailProps = {
  thumbnail_bg_sm?: string | IGatsbyImageData;
  thumbnail_bg_lg?: string | IGatsbyImageData;
  thumbnail_alt: string;
  cover_image: string | IGatsbyImageData;
  cover_alt: string;
  link: string;
  authors?: AuthorType[];
  page_number: string;
  tags: TagType[];
  categories: CategoryType[];
  title: string;
  description: React.ReactNode;
  id: string;
  light_theme?: boolean;
  isWide?: boolean;
};

const ReportThumbnail: FC<ReportThumbnailProps> = ({
  cover_image,
  thumbnail_bg_lg,
  thumbnail_bg_sm,
  link,
  page_number,
  tags,
  categories,
  title,
  description,
  authors,
  light_theme = true,
  thumbnail_alt,
  cover_alt,
  isWide,
}) => {
  return (
    <ThumbnailWrapper>
      <LinkWrapper to={link}>
        <ImgWrapper>
          {thumbnail_bg_sm && thumbnail_bg_lg && (
            <ImgContainer
              mobile={thumbnail_bg_sm}
              tablet={thumbnail_bg_lg}
              desktop={thumbnail_bg_lg}
              alt={thumbnail_alt}
              isWide={isWide}
            />
          )}
          <ThumbnailImg image={cover_image} alt={cover_alt} />
        </ImgWrapper>
        <BylineWrapper>
          {authors && authors.length > 0 && (
            <AuthorByline
              data={authors}
              size="sm"
              disableSocialLinks
              light={light_theme}
            />
          )}
          <PageNumber>{page_number}</PageNumber>
        </BylineWrapper>
        <TextWrapper>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </TextWrapper>
      </LinkWrapper>
      <CategorySection tags={tags} categories={categories} asLinks />
    </ThumbnailWrapper>
  );
};

export default ReportThumbnail;
