import React, { ForwardedRef } from 'react';
import styled from 'styled-components';
import LazyRevealWrapper from '../LazyRevealWrapper';
import StyledPicture from './StyledPicture';

const PicturePlaceholder = styled.div<{ $width?: number; $height?: number }>`
  width: 100%;
  height: 0;
  overflow: hidden;

  /* Take the aspect ratio of the image to create a flexible placeholder */
  padding-top: ${({ $width, $height }) =>
    $height && $width ? ($height / $width) * 100 : 100}%;
`;

export type SourceData = {
  media?: string;
  srcSet: string; // "src1x.jpg 1x, src2x.jpg 2x" as per the original picture element
  dimensions?: [number, number];
};
export type MediaSources = Array<SourceData>;

export type Props = {
  pictureRef?: ForwardedRef<HTMLElement>;
  className?: string;
  fallbackSrc: string;
  fallbackDimensions?: [number, number];
  alt?: string;
  sources?: MediaSources;
  placeholderColor?: string;
  onLoad?: () => void;
  readyToLoad?: boolean;
  style?: Record<string, unknown>;
  removeBottomRoundCornersOnDesktop?: boolean;
};

type State = {
  loaded: boolean;
};

class Picture extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.handleImageLoaded = this.handleImageLoaded.bind(this);
    this.checkLoaded = this.checkLoaded.bind(this);
    this.setLoaded = this.setLoaded.bind(this);

    this.state = {
      loaded: false,
    };
  }

  handleImageLoaded(): void {
    this.setLoaded();
  }

  setLoaded(): void {
    // setting timeout because there is a bug where the
    // opacity transition will not be applied if the image has just finished loading
    window.setTimeout(() => {
      this.setState({
        loaded: true,
      });
    }, 15);

    const { onLoad } = this.props;
    if (onLoad) onLoad();
  }

  checkLoaded(ref: HTMLImageElement | null): void {
    if (ref && ref.complete) {
      this.setLoaded();
    }
  }

  render(): React.ReactNode {
    const {
      pictureRef,
      className,
      fallbackSrc,
      alt,
      sources = [],
      fallbackDimensions,
      placeholderColor,
      readyToLoad = true,
      style,
      removeBottomRoundCornersOnDesktop,
    } = this.props;
    const { loaded } = this.state;

    const sourceDimensions = sources
      .filter((source) => !!source.dimensions)
      .reverse();

    const dimension =
      fallbackDimensions ||
      (sourceDimensions[0] && sourceDimensions[0].dimensions);

    const [width, height] = dimension || [1, 1];

    return (
      <LazyRevealWrapper>
        {(placeholderRef, readyToReveal) =>
          readyToReveal ? (
            <StyledPicture
              ref={pictureRef}
              className={className}
              placeholderColor={placeholderColor || 'transparent'}
              reveal={loaded}
              fallbackDimensions={fallbackDimensions}
              sourceDimensions={sourceDimensions}
              style={style}
              removeBottomRoundCornersOnDesktop={
                removeBottomRoundCornersOnDesktop
              }
            >
              {/* render the picture <source> elements: */}
              {sources.map((source) => (
                <source
                  media={source.media}
                  srcSet={source.srcSet}
                  key={source.srcSet}
                />
              ))}
              {/* lazily render the picture's <img> element
            in order to lazyload the final source that is chosen */}
              <img
                src={fallbackSrc}
                alt={alt}
                ref={this.checkLoaded}
                onLoad={this.handleImageLoaded}
                key="fallback"
                style={{ display: `${!readyToLoad ? 'none' : ''}` }}
              />
            </StyledPicture>
          ) : (
            <PicturePlaceholder
              $width={width}
              $height={height}
              ref={placeholderRef}
            />
          )
        }
      </LazyRevealWrapper>
    );
  }
}

export default React.forwardRef<HTMLElement, Props>((props, ref) => (
  <Picture {...props} pictureRef={ref} />
));
