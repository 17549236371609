import React, { FC } from 'react';
import styled from 'styled-components';
import { useLazyLoad } from '../../util/useLazyLoad';

const InView = styled.div<{
  readyToLoad: boolean;
  delay: number;
}>`
  /* opacity: ${({ readyToLoad }) => (readyToLoad ? '1' : '0')}; */
  /* transition: 300ms ease-in opacity, transform ease-out 400ms; */
  /* transition-delay: ${({ delay }) => delay}ms; */
  /* transform: ${({ readyToLoad }) =>
    readyToLoad ? 'translateY(0)' : 'translateY(3rem)'}; */
`;

const NUMBER_TO_STAGGER = 3;
const DELAY_MILLISECONDS = 100;
const WAIT_FOR_PAGE = true;
const ROOT_MARGIN = '0px';
const THRESHOLD = 0.001;

interface RenderAfterPageTransitionProps {
  delay?: number;
}

const RenderIntoView: FC<{ delay: number }> = ({ children, delay }) => {
  const { ref, readyToLoad } = useLazyLoad(
    WAIT_FOR_PAGE,
    ROOT_MARGIN,
    THRESHOLD
  );

  return (
    <InView ref={ref} readyToLoad={readyToLoad} delay={delay}>
      {children}
    </InView>
  );
};

const RenderAfterPageTransition: FC<RenderAfterPageTransitionProps> = ({
  children,
  delay,
}) => {
  return (
    <>
      {React.Children.map(children, (child, index) => (
        <RenderIntoView
          delay={
            delay ?? index < NUMBER_TO_STAGGER ? index * DELAY_MILLISECONDS : 0
          }
        >
          {child}
        </RenderIntoView>
      ))}
    </>
  );
};

export default RenderAfterPageTransition;
