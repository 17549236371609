import React, { FC } from 'react';
import { Section } from '../../layout/Section';
import { Grid } from '../../layout/Grid';
import { Layout } from '../../layout/Layout';
import { Header, Title } from './PageHeader.style';

export type PageHeaderProps = {
  title: string;
  variant?: 'default' | 'xl';
};

const PageHeaderModule: FC<PageHeaderProps> = ({ title, variant }) => {
  return (
    <Section>
      <Layout>
        <Grid>
          <Header className={variant}>
            <Title>{title}</Title>
          </Header>
        </Grid>
      </Layout>
    </Section>
  );
};

export default PageHeaderModule;
