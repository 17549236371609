import styled from 'styled-components';
import { typeScale } from '../../../styles/newTypography';
import { spacing } from '../../../styles/newThemeVariables.styles';
import { breakpointUp } from '../../../styles/mediaUtils';
import { Grid } from '../../layout/Grid';
import { motion } from 'framer-motion';

export const Title = styled.p`
  ${typeScale.mobile.mono.md};
  margin-bottom: ${spacing.space8};
  grid-column: 1 / -1;

  @media ${breakpointUp.tablet} {
    margin-bottom: ${spacing.space56};
  }
`;

export const ReelModuleWrapper = styled(Grid)`
  grid-column: 1 / -1;
`;

export const Item = styled(motion.div)`
  margin: 0 auto;

  @media ${breakpointUp.tablet} {
    grid-column: span 4;
  }

  @media ${breakpointUp.desktop} {
    grid-column: span 2;
  }
`;
