import React, { FC } from 'react';
import { TagType } from '../../../old/storyblok/tags-context';
import { TagsContainer, TagsList } from './TagsBlock.styles';
import { LabelButton } from '../../components/Buttons/Label';
import CMSLink from '../../components/CMSLink';

export type TagsProps = {
  tags: TagType[];
};

const TagsBlock: FC<TagsProps> = ({ tags }) => {
  return (
    <TagsContainer>
      <TagsList>
        {tags.map((tag) => (
          <CMSLink key={tag.uuid} to={tag.full_slug}>
            <LabelButton>{tag.title}</LabelButton>
          </CMSLink>
        ))}
      </TagsList>
    </TagsContainer>
  );
};

export default TagsBlock;
