import React, { FC } from 'react';
import ImageRenderer from './ImageRenderer';
import ArticleLink from '../ArticleLink';

type TextLinkMark = {
  attrs: {
    href: string;
    linktype: 'url';
    target?: '_blank';
  };
  type: 'link';
};

type Text = {
  type: 'text';
  text: string;
  marks?: Array<
    { type: 'bold' | 'italic' | 'strike' | 'underline' | 'code' } | TextLinkMark
  >;
};

type Image = {
  type: 'image';
  attrs: {
    src: string;
    alt: string;
  };
};

export type InlineType = Text | Image;

type InlineRendererProps = { content?: InlineType | InlineType[] };

const InlineRenderer: FC<InlineRendererProps> = ({ content }) => {
  if (!content) {
    return <></>;
  }

  if (content instanceof Array) {
    return (
      <>
        {content.map((t, i) => (
          <InlineRenderer content={t} key={i} />
        ))}
      </>
    );
  }

  if (content.type === 'image') {
    return <ImageRenderer image={content.attrs.src} alt={content.attrs.alt} />;
  }

  let Content: FC = ({ children }) => <>{children}</>;

  if (content.marks) {
    content.marks.forEach((mark) => {
      const OldContent = Content;
      switch (mark.type) {
        case 'bold':
          Content = ({ children }) => (
            <strong>
              <OldContent>{children}</OldContent>
            </strong>
          );
          break;
        case 'italic':
          Content = ({ children }) => (
            <em>
              <OldContent>{children}</OldContent>
            </em>
          );
          break;
        case 'strike':
          Content = ({ children }) => (
            <s>
              <OldContent>{children}</OldContent>
            </s>
          );
          break;
        case 'underline':
          Content = ({ children }) => (
            <u>
              <OldContent>{children}</OldContent>
            </u>
          );
          break;
        case 'link':
          Content = ({ children }) => {
            return (
              <ArticleLink to={mark.attrs.href} target={mark.attrs.target}>
                <OldContent>{children}</OldContent>
              </ArticleLink>
            );
          };
          break;
        case 'code':
          Content = ({ children }) => (
            <code>
              <OldContent>{children}</OldContent>
            </code>
          );
        default:
      }
    });
  }

  return <Content>{content.text}</Content>;
};

export const ParagraphRenderer: FC<InlineRendererProps> = ({ content }) => {
  if (!(content instanceof Array)) {
    return (
      <p>
        <InlineRenderer content={content} />
      </p>
    );
  }
  const groups: Array<{ type: 'text' | 'image'; bits: InlineType[] }> = [];
  content.forEach((bit) => {
    if (!groups[groups.length - 1]) {
      groups.push({ type: bit.type, bits: [bit] });
      return;
    }
    if (groups[groups.length - 1].type !== bit.type) {
      groups.push({ type: bit.type, bits: [bit] });
      return;
    }
    groups[groups.length - 1].bits.push(bit);
  });
  return (
    <>
      {groups.map((group, index) =>
        group.type === 'text' ? (
          <p key={`${JSON.stringify(group.bits)}-${index}`}>
            <InlineRenderer content={group.bits} />
          </p>
        ) : (
          <InlineRenderer
            key={`${JSON.stringify(group.bits)}-${index}`}
            content={group.bits}
          />
        )
      )}
    </>
  );
};

export default InlineRenderer;
