import React from 'react';
import SbEditable, { SbEditableContent } from 'storyblok-react';
import {
  BlokComponent,
  NewStoryblokMetaData,
  StoryblokRelatedPosts,
} from '../../types/blok.model';
import { useNavTheme } from '../../old/components/Nav/NavThemeSetter';
import defaultTheme, { darkTheme } from '../../styles/themeVariables';
import RenderAfterPageTransition from '../../old/components/RenderAfterPageTransition';
import styled, { ThemeProvider } from 'styled-components';
import { ThemeModeContextProvider } from '../../util/useThemeMode';

import { useCaseStudyThumbnails } from '../../util/useCaseStudyThumbnails';
import isEmpty from '../../util/isEmpty';

import CaseStudyRelatedPosts from '../modules/CaseStudyRelatedPosts';
import ContactFormModule from '../modules/ContactFormModule';

interface CaseStudySchema extends NewStoryblokMetaData {
  title: string;
  head?: SbEditableContent[];
  body?: SbEditableContent[];
  bodyLower?: SbEditableContent[];
  lightHeader: boolean;
  related_work?: StoryblokRelatedPosts;
}

const Background = styled.div`
  background: ${({ theme }) => theme.bgPrimary};
`;

const CaseStudyPage: BlokComponent<CaseStudySchema> = ({ blok, Dynamic }) => {
  useNavTheme(blok.lightHeader ? defaultTheme : darkTheme(defaultTheme));

  const relatedPosts = useCaseStudyThumbnails(blok?.related_work ?? []);

  return (
    <SbEditable content={blok}>
      <div>
        {blok.head ? (
          blok.head.map((insideBlok) => (
            <Dynamic key={insideBlok._uid} blok={insideBlok} />
          ))
        ) : (
          <></>
        )}

        <ThemeModeContextProvider themeMode={'light'}>
          <ThemeProvider theme={defaultTheme}>
            <Background>
              <RenderAfterPageTransition>
                {blok.body?.map((insideBlok) => (
                  <Dynamic key={insideBlok._uid} blok={insideBlok} />
                ))}
              </RenderAfterPageTransition>
            </Background>
          </ThemeProvider>
        </ThemeModeContextProvider>

        <ThemeModeContextProvider themeMode={'dark'}>
          <ThemeProvider theme={darkTheme(defaultTheme)}>
            <Background>
              <RenderAfterPageTransition>
                {blok.bodyLower?.map((insideBlok) => (
                  <Dynamic key={insideBlok._uid} blok={insideBlok} />
                ))}
                {relatedPosts && !isEmpty(relatedPosts) && (
                  <CaseStudyRelatedPosts posts={relatedPosts} />
                )}
                <ContactFormModule
                  form_data={'7dfec556-192c-483f-bc39-246e44bb1f7b'}
                  tagline={'READY TO GET STARTED?'}
                  title={'Transform how your most important work is delivered'}
                  subtitle={
                    'Meet the team, discuss your ideas with our experts and receive a proposal for your project.'
                  }
                />
              </RenderAfterPageTransition>
            </Background>
          </ThemeProvider>
        </ThemeModeContextProvider>
      </div>
    </SbEditable>
  );
};

export default React.memo(CaseStudyPage);
