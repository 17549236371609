import React from 'react';
import SbEditable from 'storyblok-react';
import CardReportContent, { CardReportContentProps } from '.';
import { BlokComponent, StoryblokAsset } from '../../../types/blok.model';
import { useReportsThumbnails } from '../../../util/useReportsThumbnails';

export interface CardReportContentBlokProps extends CardReportContentProps {
  contentId: string;
  logo: StoryblokAsset;
}

const CardReportContentBlok: BlokComponent<CardReportContentBlokProps> = ({
  blok,
}) => {
  const article = useReportsThumbnails([blok.contentId])?.[0];

  return (
    <SbEditable content={blok}>
      <CardReportContent
        {...blok}
        title={article?.title}
        link={article?.link}
        authors={article?.authors}
        thumbnailAlt={article?.thumbnail_alt}
        mobile={{
          aspect: '3 / 4',
          thumbnail: article?.thumbnail_3x4,
        }}
        tablet={{
          aspect: '4 / 3',
          thumbnail: article?.thumbnail_4x3,
        }}
        desktop={{
          aspect: '4 / 3',
          thumbnail: article?.thumbnail_4x3,
        }}
        coverImage={article?.cover_image}
        coverImageAlt={article?.cover_alt}
      />
    </SbEditable>
  );
};

export default CardReportContentBlok;
