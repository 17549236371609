import React, { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import defaultTheme, { darkTheme } from '../../../styles/themeVariables';
import { truncateText } from '../../../util/truncateText';
import BlogPostThumbnail, {
  BlogThumbnailProps,
} from '../../blocks/BlogPostThumbnail';
import {
  SectionWrapper,
  Title,
  TitleRow,
  RelatedPostsGrid,
  RelatedWrapper,
} from './BlogRelatedPosts.styles';
import { Layout } from '../../layout/Layout';
import LinkButton from '../../components/Button/LinkButton';

export type BlogRelatedPostsProps = {
  posts: BlogThumbnailProps[] | undefined;
  light?: boolean;
};

const BlogRelatedPosts: FC<BlogRelatedPostsProps> = ({ light, posts }) => {
  return (
    <ThemeProvider theme={light ? defaultTheme : darkTheme}>
      <SectionWrapper>
        <Layout>
          <RelatedWrapper>
            <TitleRow>
              <Title>RELATED POSTS</Title>
              <LinkButton
                btnsize={'sm'}
                variant={'ghost'}
                dark={true}
                href={`/blog/`}
              >
                See more work
              </LinkButton>
            </TitleRow>
            <RelatedPostsGrid>
              {posts?.map((post) => (
                <BlogPostThumbnail
                  key={post.title}
                  link={post.link}
                  thumbnail_alt={post.thumbnail_alt}
                  thumbnail_sm={post.thumbnail_sm}
                  thumbnail_lg={post.thumbnail_lg}
                  title={post.title}
                  description={truncateText(post.description, 124)}
                  tags={post.tags}
                  categories={post.categories}
                />
              ))}
            </RelatedPostsGrid>
          </RelatedWrapper>
        </Layout>
      </SectionWrapper>
    </ThemeProvider>
  );
};

export default BlogRelatedPosts;
