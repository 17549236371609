import React, { FC } from 'react';
import { Layout } from '../../layout/Layout';
import { Grid } from '../../layout/Grid';
import { Module } from './LongformTextModule.styles';

export interface LongformTextModuleProps {
  content: React.ReactNode;
}

const LongformTextModule: FC<LongformTextModuleProps> = ({ content }) => {
  return (
    <Layout variant="smallWidth">
      <Grid>
        <Module>{content}</Module>
      </Grid>
    </Layout>
  );
};

export default LongformTextModule;
