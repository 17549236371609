import React, { FC } from 'react';
import {
  Center,
  Description,
  EyebrowText,
  Title,
  CTALink,
} from './CallOut.styles';

export interface TextBlokProps {
  eyebrow?: string;
  title?: string;
  description?: string;
  ctaText?: string;
  ctaLink?: string;
}

const TextBlock: FC<TextBlokProps> = ({
  eyebrow,
  title,
  description,
  ctaText,
  ctaLink,
}) => {
  return (
    <Center>
      {eyebrow && <EyebrowText>{eyebrow}</EyebrowText>}
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
      {ctaText && (
        <CTALink size={'md'} href={ctaLink}>
          {ctaText}
        </CTALink>
      )}
    </Center>
  );
};

export default TextBlock;
