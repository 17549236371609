import React, { FC } from 'react';
import { List, Link, Separator, Item } from './InlineFilterList.styles';
import { CategoryType } from '../../../../old/storyblok/categories-context';
import { TagType } from '../../../../old/storyblok/tags-context';
import { Section } from '../../../layout/Section';
import { Layout } from '../../../layout/Layout';
import { Grid } from '../../../layout/Grid';
import { useLocation } from '@gatsbyjs/reach-router';

export type InlineFilterProps = {
  categoryList: CategoryType[];
  tagList: TagType[];
  resetLabel?: string;
  resetFilterUrl?: string;
};

const InlineFilterMenu: FC<InlineFilterProps> = ({
  categoryList,
  tagList,
  resetLabel = 'All',
  resetFilterUrl,
}) => {
  const location = useLocation();

  return (
    <Section>
      <Layout>
        <Grid>
          <List>
            {resetFilterUrl && (
              <Item>
                <Link
                  isActive={
                    location.pathname?.includes(resetFilterUrl) &&
                    !location.pathname?.includes('tag') &&
                    !location.pathname?.includes('category')
                  }
                  to={resetFilterUrl}
                  filterUrl={false}
                >
                  {resetLabel}
                </Link>
                <Separator>/</Separator>
              </Item>
            )}

            {categoryList.map((category) => {
              return (
                <Item key={category.uuid}>
                  <Link
                    isActive={location.pathname?.includes(
                      `category/${category.slug}`
                    )}
                    to={`category/${category.slug}/`}
                  >
                    {category.title}
                  </Link>
                  <Separator>/</Separator>
                </Item>
              );
            })}

            {tagList.map((tag, index) => {
              const lastChild = index === tagList.length - 1;

              return (
                <Item key={tag.uuid}>
                  <Link
                    isActive={location.pathname?.includes(`tag/${tag.slug}`)}
                    to={`tag/${tag.slug}/`}
                  >
                    {tag.title}
                  </Link>
                  {!lastChild && <Separator>/</Separator>}
                </Item>
              );
            })}
          </List>
        </Grid>
      </Layout>
    </Section>
  );
};

export default InlineFilterMenu;
