import styled from 'styled-components';
import ContentWrapper from '../../../old/components/style-components/ContentWrapper';
import { breakpointUp } from '../../../styles/mediaUtils';
import { textSizes } from '../../../styles/typography';
import {
  TagsSection,
  Description,
  ImgContainer,
} from '../../blocks/BlogPostThumbnail/BlogPostThumbnail.styles';
import Button from '../../components/Button';
import { StyledRow } from '../FeaturedBlogRow/FeaturedBlogRow.styles';
import { Grid } from '../../layout/Grid';

export const SectionWrapper = styled.section`
  background-color: var(--fg1, ${({ theme }) => theme.bgPrimary});
`;

export const RelatedWrapper = styled(ContentWrapper)`
  ${StyledRow} {
    @media ${breakpointUp.sm} {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }
  }

  ${ImgContainer} {
    img {
      height: 33.75rem;
    }
  }
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3rem 0 1.5rem 0;
  align-items: flex-end;
`;

export const Title = styled.h2`
  ${textSizes.xxs};
  font-weight: 700;
  letter-spacing: 0.15em;
  color: var(--fg2, ${({ theme }) => theme.fg});
  margin-bottom: 0;
`;

export const StyledButton = styled(Button)`
  border-color: white;
`;

export const RelatedPostsWrapper = styled(Grid)`
  padding-bottom: 3rem;
  row-gap: 3rem;

  ${Description} {
    color: ${({ theme }) => theme.charSlate};
  }

  ${TagsSection} {
    color: ${({ theme }) => theme.charSlate};

    li {
      border-bottom: 0.125rem solid rgba(151, 151, 151, 0.15);
    }
  }
`;
