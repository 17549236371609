import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';
import DynamicComponent from '../new/components/DynamicComponent';
import StoryblokImageProvider from '../old/storyblok/asset-context';
import { StoryblokMetaProvider } from '../old/storyblok/meta-context';
import {
  CategoriesContextProvider,
  CategoryType,
} from '../old/storyblok/categories-context';
import { TagsContextProvider, TagType } from '../old/storyblok/tags-context';
import {
  AuthorsContextProvider,
  AuthorType,
} from '../old/storyblok/authors-context';
import { BlogPostsContextProvider } from '../old/storyblok/blogPosts-context';
import { CaseStudiesContextProvider } from '../old/storyblok/caseStudies-context';
import {
  StoryblokBlogPost,
  StoryblokCaseStudy,
  StoryblokReportPage,
} from '../types/blok.model';
import {
  WhitePaperFormDataContextProvider,
  WhitePaperFormDataType,
} from '../old/storyblok/hubspotFormsData-context';
import {
  ContactFormDataContextProvider,
  ContactFormDataType,
} from '../util/useContactFormData';
import { ReportsContextProvider } from '../old/storyblok/reports-context';
import { ViewportContextProvider } from '../util/useViewport';
import { Pagination } from '../types';

import getStory from '../old/storyblok/util/getStory';
import SEO from '../new/components/SEO';

interface PageContextProps {
  slug: string;
  allCategories: CategoryType[];
  allTags: TagType[];
  blogPosts?: StoryblokBlogPost[];
  allBlogPosts?: StoryblokBlogPost[];
  reports?: StoryblokReportPage[];
  allReports?: StoryblokReportPage[];
  caseStudies?: StoryblokCaseStudy[];
  allCaseStudies?: StoryblokCaseStudy[];
  allAuthors?: AuthorType[];
  whitePaperFormData?: WhitePaperFormDataType[];
  contactFormData?: ContactFormDataType[];
  metaData?: {
    title: string;
    description: string;
    image: string;
    no_index: boolean;
  };
  pagination?: Pagination;
}

const StoryblokEntry: FC<
  PageProps<GatsbyTypes.StoryblokEntryQuery, PageContextProps>
> = ({ data, pageContext }) => {
  const pagination = pageContext.pagination;
  const storyData = data.storyblokEntry;

  if (!storyData || !storyData.content) {
    return <span>{JSON.stringify(storyData, null, 2)}</span>;
  }
  const story = getStory(storyData);

  const imageAssets = [
    data.storyblokEntry?.assets,
    pageContext.allBlogPosts?.map((post) => post.assets).flat(2),
    pageContext.allCaseStudies?.map((post) => post.assets).flat(2),
    pageContext.allReports?.map((post) => post.assets).flat(2),
  ].flat(2) as GatsbyTypes.StoryblokAssetFragment[];

  const getAuthor = () => {
    if (pageContext.allAuthors && story?.content?.author) {
      return pageContext.allAuthors.find(
        (author) => author.uuid === story?.content?.author
      )?.name;
    }
    return undefined;
  };

  return (
    // We supply image data here that we extracted from storyblok, see `gatsby-node.js` for more details
    <>
      {pageContext?.metaData && (
        <SEO
          image={pageContext.metaData.image}
          title={pageContext.metaData.title}
          description={pageContext.metaData.description}
          canonicalAddress={story.content?.canonical_address as string}
          datePublished={data?.storyblokEntry?.date_published as string}
          dateModified={data?.storyblokEntry?.date_modified as string}
          author={getAuthor()}
          no_index={pageContext.metaData.no_index}
        />
      )}
      <StoryblokImageProvider imageData={imageAssets}>
        <StoryblokMetaProvider value={story}>
          <ViewportContextProvider>
            <ContactFormDataContextProvider data={pageContext.contactFormData}>
              <WhitePaperFormDataContextProvider
                data={pageContext.whitePaperFormData}
              >
                <CategoriesContextProvider data={pageContext.allCategories}>
                  <TagsContextProvider data={pageContext.allTags}>
                    <AuthorsContextProvider data={pageContext.allAuthors}>
                      <ReportsContextProvider
                        data={{
                          posts: pageContext.reports,
                          allPosts: pageContext.allReports,
                        }}
                        pagination={pagination}
                      >
                        <BlogPostsContextProvider
                          data={{
                            posts: pageContext.blogPosts,
                            allPosts: pageContext.allBlogPosts,
                          }}
                          pagination={pagination}
                        >
                          <CaseStudiesContextProvider
                            data={{
                              posts: pageContext.caseStudies,
                              allPosts: pageContext.allCaseStudies,
                            }}
                            pagination={pagination}
                          >
                            <DynamicComponent
                              blok={story.content}
                              story={story}
                            />
                          </CaseStudiesContextProvider>
                        </BlogPostsContextProvider>
                      </ReportsContextProvider>
                    </AuthorsContextProvider>
                  </TagsContextProvider>
                </CategoriesContextProvider>
              </WhitePaperFormDataContextProvider>
            </ContactFormDataContextProvider>
          </ViewportContextProvider>
        </StoryblokMetaProvider>
      </StoryblokImageProvider>
    </>
  );
};

export default StoryblokEntry;

export const query = graphql`
  fragment StoryblokAsset on File {
    childImageSharp {
      gatsbyImageData(
        layout: FULL_WIDTH
        quality: 60
        webpOptions: { quality: 60 }
        breakpoints: [576, 1024, 1920]
        formats: [WEBP]
        placeholder: BLURRED
      )
    }
    publicURL
    url
  }
  fragment StoryblokStory on StoryblokEntry {
    id
    name
    full_slug
    slug
    created_at
    internalId
    content
    date_modified: published_at
    date_published: first_published_at
    assets {
      ...StoryblokAsset
    }
  }

  fragment StoryblokBlog on StoryblokEntry {
    assets {
      ...StoryblokAsset
    }
    content
    slug
    published_at
  }

  query StoryblokEntry($slug: String!) {
    storyblokEntry(full_slug: { eq: $slug }) {
      ...StoryblokStory
    }
  }
`;
