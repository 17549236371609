import React from 'react';
import SbEditable from 'storyblok-react';
import CardCaseStudy, { CardCaseStudyProps } from '.';
import { BlokComponent } from '../../../types/blok.model';
import { useCaseStudyThumbnails } from '../../../util/useCaseStudyThumbnails';

export interface CardCaseStudyBlokProps extends CardCaseStudyProps {
  contentId: string;
}

const CardCaseStudyBlok: BlokComponent<CardCaseStudyBlokProps> = ({ blok }) => {
  const post = useCaseStudyThumbnails([blok.contentId])?.[0];

  return (
    <SbEditable content={blok}>
      {post && (
        <CardCaseStudy
          {...blok}
          title={post.title}
          link={post.link}
          clientLogo={post.logo}
          clientLogoDark={post.logoDark}
          clientName={post.client}
          clientLogoAlt={post.client}
          thumbnailAlt={post.thumbnail_alt}
          mobile={{
            aspect: '3 / 4',
            thumbnail: post.thumbnail_3x4,
          }}
          tablet={{
            aspect: '16 / 9',
            thumbnail: post.thumbnail_16x9,
          }}
          desktop={{
            aspect: '3 / 4',
            thumbnail: post.thumbnail_3x4,
          }}
          desktopLarge={{
            aspect: '4 / 3',
            thumbnail: post.thumbnail_4x3,
          }}
        />
      )}
    </SbEditable>
  );
};

export default CardCaseStudyBlok;
