import styled, { css } from 'styled-components';
import StoryblokAssetImage from '../../components/AssetImage';
import { breakpointUp } from '../../../styles/mediaUtils';
import SectionWrapper from '../../components/SectionWrapper';

export type ImageType = 'centered' | 'fullBleed';
export type DuoLayout = 'equal' | 'offsetRight' | 'offsetLeft';
interface ImageProps {
  showOnMobile?: boolean;
}

export const GallerySectionWrapper = styled(SectionWrapper)`
  padding: 0;
`;

export const GallerySection = styled.div<{
  bg: string;
  bottomPadding: boolean;
  topPadding: boolean;
  type: ImageType;
}>`
  --bg1: ${({ bg }) => bg};

  grid-column: ${({ type }) => (type === 'fullBleed' ? 'full' : 'main')};
  background-color: var(--bg1);
  ${({ bottomPadding }) =>
    bottomPadding &&
    css`
      padding-bottom: 1.5rem;

      @media ${breakpointUp.sm} {
        padding-bottom: 3.5rem;
      }

      @media ${breakpointUp.lg} {
        padding-bottom: 7rem;
      }
    `};
  ${({ topPadding }) =>
    topPadding &&
    css`
      padding-top: 1.5rem;

      @media ${breakpointUp.sm} {
        padding-top: 3.5rem;
      }

      @media ${breakpointUp.lg} {
        padding-top: 7rem;
      }
    `};
`;

export const GalleryWrapper = styled.div<{
  type: ImageType;
  layout?: DuoLayout;
}>`
  display: grid;
  max-height: 90vh;
  height: 100%;

  @media ${breakpointUp.sm} {
    display: grid;
  }

  img {
    object-fit: cover;
  }

  ${({ type }) =>
    type === 'fullBleed'
      ? css`
          max-width: 100%;
          padding: 0;
        `
      : css`
          column-gap: 1rem;
          row-gap: 1rem;
        `};
`;

export const StyledImage = styled(StoryblokAssetImage).withConfig({
  shouldForwardProp: (prop) => !['showOnMobile'].includes(prop),
})<ImageProps>`
  width: 100%;
  display: ${({ showOnMobile }) => (showOnMobile ? 'block' : 'none')};

  img {
    /* TODO: remove border radius from genneric picture component */
    border-radius: 0;
    width: 100%;
  }

  @media ${breakpointUp.sm} {
    display: block;
  }
`;

export const StyledMobImage = styled(StoryblokAssetImage).withConfig({
  shouldForwardProp: (prop) => !['showOnMobile'].includes(prop),
})<ImageProps>`
  width: 100%;

  img {
    /* TODO: remove border radius from genneric picture component */
    border-radius: 0;
    width: 100%;
  }

  @media ${breakpointUp.sm} {
    display: none;
  }
`;
