import styled, { css } from 'styled-components';
import SocialIcons from '../SocialIcons';
import { breakpointUp } from '../../../styles/mediaUtils';
import { textSizes } from '../../../styles/typography';

export const BylineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
`;

export const StyledSingleAuthorWrap = styled.div`
  display: grid;
  grid-template-columns: 2.5rem 4fr;
  column-gap: 1rem;

  @media ${breakpointUp.sm} {
    grid-template-columns: 3rem 4fr;
  }
`;

export const StyledMUltipleAuthorWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  & > picture {
    margin-left: -1.875rem;
  }

  & > picture:nth-of-type(1) {
    margin-left: 0;
  }
`;

export const StylNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StylName = styled.span<{ size: 'sm' | 'md' }>`
  ${({ size }) => {
    if (size === 'sm') {
      return css`
        ${textSizes.xxs};
      `;
    }

    if (size === 'md') {
      return css`
        ${textSizes.xxs};

        @media ${breakpointUp.sm} {
          ${textSizes.xs};
        }
      `;
    }
  }};
  color: ${({ theme }) => theme.fg};
`;

export const StylDescription = styled.span`
  ${textSizes.xxs}
  color: ${({ theme }) => theme.fg};
  opacity: 50%;

  @media ${breakpointUp.sm} {
    ${textSizes.xs};
  }
`;

export const DesktopSocialIcons = styled(SocialIcons)`
  display: none;

  @media ${breakpointUp.sm} {
    display: flex;
    align-items: center;

    li {
      margin: 0 0.25rem;
    }

    svg path {
      fill: ${({ theme }) => theme.fg};
    }
  }
`;
