import React from 'react';
import SbEditable from 'storyblok-react';
import CallOutModule, { CallOutModuleProps } from '.';
import {
  BlokComponent,
  ImageAsset,
  VideoAsset,
  StoryblokLink,
} from '../../../types/blok.model';
import { ensureTrailingSlash } from '../../../util/ensureTranilingSlash';

type CallOutModuleBlokSchema = Omit<CallOutModuleProps, 'ctaLink'> & {
  assets: (ImageAsset | VideoAsset)[];
  ctaLink: StoryblokLink;
};

const videoAssetSource = (asset: VideoAsset) => ({
  mobile: {
    ...asset.mobile,
    poster: asset.mobilePoster.filename,
  },
  tablet: {
    ...asset.tablet,
    poster: asset.tabletPoster.filename,
  },
  desktop: {
    ...asset.desktop,
    poster: asset.desktopPoster.filename,
  },
});

const imageAssetSource = (asset: ImageAsset) => ({
  mobile: asset.mobile,
  tablet: asset.tablet,
  desktop: asset.desktop,
});

const CallOutModuleBlok: BlokComponent<CallOutModuleBlokSchema> = ({
  blok,
}) => {
  const ctaLink = ensureTrailingSlash(
    blok.ctaLink?.cached_url ?? blok.ctaLink?.url
  );

  const items = blok.assets.map((asset) => {
    const sources =
      asset.component === 'videoAsset'
        ? videoAssetSource(asset)
        : imageAssetSource(asset);

    return {
      id: asset._uid,
      type: asset.component,
      sources,
    };
  });

  return (
    <SbEditable content={blok}>
      <CallOutModule {...blok} items={items} ctaLink={ctaLink} />
    </SbEditable>
  );
};

export default CallOutModuleBlok;
