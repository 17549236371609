import { IGatsbyImageData } from 'gatsby-plugin-image';
import React, { FC, useMemo } from 'react';
import {
  Link,
  Image,
  Card,
  Title,
  Authors,
  AuthorDetails,
  AuthorAvatars,
  AuthorAvatar,
  AuthorName,
  CoverImage,
  ImageWrapper,
  Description,
} from './CardReportContent.styles';
import { AuthorType } from '../../../old/storyblok/authors-context';
import { TagType } from '../../../old/storyblok/tags-context';
import TagsBlock from '../TagsBlock';

const joinNames = (authors: AuthorType[]): string => {
  return authors.map((author) => author.name).join(' & ');
};

export type CardReportContentProps = {
  link?: string;
  thumbnailAlt?: string;
  coverImage?: string | IGatsbyImageData;
  coverImageAlt?: string;
  mobile?: {
    aspect?: string;
    thumbnail?: string | IGatsbyImageData;
  };
  tablet?: {
    aspect?: string;
    thumbnail?: string | IGatsbyImageData;
  };
  desktop?: {
    aspect?: string;
    thumbnail?: string | IGatsbyImageData;
  };
  authors?: AuthorType[];
  title?: string;
  tags?: TagType[];
  description?: string;
};

const CardReportContent: FC<CardReportContentProps> = ({
  link,
  thumbnailAlt,
  mobile,
  tablet,
  desktop,
  coverImage,
  coverImageAlt,
  title,
  authors,
  tags,
  description,
}) => {
  const trimmedAuthors = useMemo(
    () =>
      authors?.map((author) => {
        return {
          ...author,
          name: author?.name,
          description: author?.description.split(' at Elsewhen')?.[0],
        };
      }),
    [authors]
  );

  return (
    <Card>
      <Link to={link ?? ''}>
        <ImageWrapper>
          {coverImage && coverImageAlt && (
            <CoverImage
              alt={coverImageAlt}
              mobile={coverImage}
              tablet={coverImage}
              desktop={coverImage}
            />
          )}
          {thumbnailAlt &&
            mobile?.thumbnail &&
            tablet?.thumbnail &&
            desktop?.thumbnail && (
              <Image
                alt={thumbnailAlt}
                mobile={mobile.thumbnail}
                tablet={tablet.thumbnail}
                desktop={desktop.thumbnail}
                $aspectMobile={mobile.aspect}
                $aspectTablet={tablet.aspect}
                $aspectDesktop={desktop.aspect}
              />
            )}
        </ImageWrapper>
        {!description && title && <Title>{title}</Title>}
        {trimmedAuthors && (
          <Authors>
            <AuthorAvatars>
              {trimmedAuthors?.map((author) => (
                <AuthorAvatar
                  key={author.uuid}
                  mobile={author.avatar}
                  tablet={author.avatar}
                  desktop={author.avatar}
                  alt={author.name}
                  loading="eager"
                />
              ))}
            </AuthorAvatars>
            <AuthorDetails>
              <AuthorName>
                {trimmedAuthors.length > 1
                  ? joinNames(trimmedAuthors)
                  : trimmedAuthors[0].name}
              </AuthorName>
            </AuthorDetails>
          </Authors>
        )}
        {description && title && <Title>{title}</Title>}
        {description && <Description>{description}</Description>}
      </Link>
      {tags && <TagsBlock tags={tags} />}
    </Card>
  );
};

export default CardReportContent;
