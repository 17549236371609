import styled from 'styled-components';
import { breakpointUp } from '../../../styles/mediaUtils';
import StoryblokAssetImage from '../../components/AssetImage';
import CMSLink from '../../components/CMSLink';
import Sectors from '../../components/Sectors';
import { colors } from '../../../styles/themeVariables';
import { textSizes } from '../../../styles/typography';
import { ResponsiveImage } from '../../components/ResponsiveImage';

export const ThumbnailWrapper = styled.div`
  display: block;
  width: 100%;
  position: relative;
`;

export const LinkWrapper = styled(CMSLink)`
  cursor: pointer;
  height: 100%;
  width: 100%;
`;

export const ImgWrapper = styled.div`
  position: relative;
  margin-bottom: 1.5rem;
  width: 100%;
`;

export const ImgContainer = styled(ResponsiveImage)<{ isWide?: boolean }>`
  position: relative;
  aspect-ratio: 3/4;

  & > div {
    height: 100%;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media ${breakpointUp.sm} {
    aspect-ratio: 16/9;
  }

  @media ${breakpointUp.md} {
    aspect-ratio: ${(props) => (props.isWide ? '21/9' : '4/3')};
  }
`;

export const ThumbnailImg = styled(StoryblokAssetImage)`
  width: auto;
  height: 75%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 1 / 1.4142;

  & > div {
    height: 100%;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
    filter: drop-shadow(0 0.40625rem 1.646875rem rgba(0, 0, 0, 0.25));
  }

  @media ${breakpointUp.sm} {
    height: 80%;
  }
`;

export const BylineWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6875rem;
  margin-bottom: 1rem;
`;

export const TextWrapper = styled.div`
  margin-bottom: 1rem;

  @media ${breakpointUp.lg} {
    max-width: 39.375rem;
  }
`;

export const PageNumber = styled.span`
  color: black;
  font-size: 0.75rem;
`;

export const Title = styled.p`
  ${textSizes.md};
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.fg};
  margin-bottom: 0.3125rem;

  @media ${breakpointUp.sm} {
    ${textSizes.lg};
  }
`;

export const Description = styled.p`
  ${textSizes.xxs};
  color: ${({ theme }) => theme.charSlate};
  margin-bottom: 0;

  @media ${breakpointUp.sm} {
    ${textSizes.xs};
  }
`;

export const CategorySection = styled(Sectors)`
  color: ${({ theme }) => theme.charSlate};
  ${textSizes.xxs};

  li {
    border-bottom: 0.125rem solid rgba(83, 88, 95, 0.15);

    :hover {
      border-color: ${colors.greyFaded};
    }
  }
`;
