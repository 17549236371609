import { CategoryType } from '../old/storyblok/categories-context';
import isEmpty from './isEmpty';

/**
 * Transforms and sorts posts based on specified criteria.
 *
 * @template T - The type of the posts being transformed, extending the base Post type.
 * @param posts - Array of posts to be transformed. Each post must have an `id`, `title`, and optionally `categories`.
 * @param featured_posts - Array of string ids to be sorted.
 * @returns Array of Transformed and sorted array of posts or undefined if no valid posts.
 */
export const transformCollection = <
  T extends {
    id: string;
    title: string;
    categories: CategoryType[];
  }
>(
  posts?: T[],
  featured_posts?: string[]
) => {
  const postsWithData = posts?.filter((post) => !isEmpty(post.title));

  const sortedPosts =
    postsWithData && postsWithData.length && featured_posts
      ? [
          ...postsWithData
            .filter((post) => featured_posts.includes(post.id))
            .sort(
              (a, b) =>
                featured_posts.indexOf(a.id) - featured_posts.indexOf(b.id)
            ),
          ...postsWithData.filter((post) => !featured_posts.includes(post.id)),
        ]
      : postsWithData;

  const transformedPosts = sortedPosts?.map((post) => {
    return {
      ...post,
      categories: post.categories?.map((category) => {
        return {
          ...category,
          full_slug: category.full_slug.replace(`${category.slug}/`, ''),
        };
      }),
    };
  });

  return transformedPosts;
};

export default transformCollection;
