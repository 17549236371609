import React, { FC } from 'react';
import BlockRenderer, { ContentBit } from './BlockRenderer';
import type { StoryblokComponentType } from 'gatsby-source-storyblok';
import { generateUid } from '../../../old/storyblok/util/generateUid';
import { colors } from '../../../styles/themeVariables';
import styled from 'styled-components';

export type RichTextDocument = {
  type: 'doc';
  content: ContentBit[];
};

const StyledBlockRenderer = styled(BlockRenderer)`
  a {
    text-decoration-color: ${colors.grey};
  }
`;

const StoryblokRichText = React.memo<{
  content: RichTextDocument;
  Dynamic: FC<{ blok: StoryblokComponentType<string> }>;
}>(({ content, Dynamic }) => {
  return (
    <>
      {content &&
        content.content.map((bit) => (
          <StyledBlockRenderer
            key={generateUid()}
            bit={bit}
            Dynamic={Dynamic}
          />
        ))}
    </>
  );
});

export default StoryblokRichText;
