import React from 'react';
import SbEditable from 'storyblok-react';
import { BlokComponent, StoryblokColor } from '../../../types/blok.model';
import ContactFormModule, { ContactFormModuleProps } from '.';
import { Colors } from '../../components/SectionWrapper';

type ContactFormModuleSchema = ContactFormModuleProps & Colors<StoryblokColor>;

export const ContactFormModuleBlok: BlokComponent<ContactFormModuleSchema> = ({
  blok,
}) => {
  const props = {
    ...blok,
  } as ContactFormModuleProps;

  return (
    <SbEditable content={blok}>
      <ContactFormModule {...props} />
    </SbEditable>
  );
};

export default ContactFormModuleBlok;
