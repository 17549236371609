import React from 'react';
import SbEditable from 'storyblok-react';
import { BlokComponent, NewStoryblokMetaData } from '../../types/blok.model';
import type { StoryblokComponentType } from 'gatsby-source-storyblok';
import { ArticleContentModuleSchema } from '../modules/ArticleContentModule/ArticleContentModule.blok';
import { useNavTheme } from '../../old/components/Nav/NavThemeSetter';
import defaultTheme, { darkTheme } from '../../styles/themeVariables';
// import RenderAfterPageTransition from '../../old/components/RenderAfterPageTransition';
import RenderAfterPageTransition from '../../old/components/RenderAfterPageTransition';

type BodySchema = StoryblokComponentType<string> & ArticleContentModuleSchema;

interface LandingPageSchema extends NewStoryblokMetaData {
  body?: BodySchema[];
  lightHeader: boolean;
}

const LandingPage: BlokComponent<LandingPageSchema> = ({ blok, Dynamic }) => {
  useNavTheme(blok.lightHeader ? defaultTheme : darkTheme(defaultTheme));

  return (
    <SbEditable content={blok}>
      <div>
        <RenderAfterPageTransition>
          {blok.body &&
            blok.body.map((insideBlok) => (
              <Dynamic key={insideBlok._uid} blok={insideBlok} />
            ))}
        </RenderAfterPageTransition>
      </div>
    </SbEditable>
  );
};

export default React.memo(LandingPage);
