import styled, { css } from 'styled-components';
import StoryblokAssetImage from '../../components/AssetImage';
import { breakpointUp } from '../../../styles/mediaUtils';
import { textSizes } from '../../../styles/typography';

import Sectors, { ListItem } from '../../components/Sectors';
import SocialIcons from '../../components/SocialIcons';
import { ResponsiveImage } from '../../components/ResponsiveImage';

const SHARE_MENU_BREAKPOINT = breakpointUp.xs;

export interface ColorOverrides<T> {
  bgGradient?: T;
}

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: end;
  position: relative;
  aspect-ratio: 3 / 4;

  @media ${breakpointUp.sm} {
    aspect-ratio: 4 / 3;
  }

  @media ${breakpointUp.md} {
    aspect-ratio: 20 / 11;
  }
`;

export const StyledRow = styled.div`
  grid-column: span 2;

  @media ${breakpointUp.sm} {
    grid-column: span 9;
  }

  @media ${breakpointUp.md} {
    grid-column: span 7;
  }
`;

export const BackgroundImage = styled(ResponsiveImage).withConfig({
  shouldForwardProp: (prop) => !['bgGradient'].includes(prop),
})<ColorOverrides<string>>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  & > div {
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 75%;
    background: linear-gradient(transparent, ${({ bgGradient }) => bgGradient});
  }
`;

export const LogoImage = styled(StoryblokAssetImage)<{
  isSimpleImage: boolean;
  size: 'sm' | 'md';
}>`
  grid-area: logo;
  display: flex;
  align-items: end;
  justify-content: start;

  .gatsby-image-wrapper {
    height: ${({ size }) => (size === 'sm' ? '1.5rem' : '2rem')} !important;
    display: flex;
    justify-content: end;

    & > div {
      background-color: transparent !important;
    }

    & > img {
      object-fit: contain !important;
      width: auto !important;
      right: auto;
    }
  }

  @media ${breakpointUp.md} {
    .gatsby-image-wrapper {
      height: ${({ size }) => (size === 'sm' ? '2rem' : '2.5rem')} !important;

      & > img {
        left: auto;
        width: 100%;
      }
    }
  }

  @media ${breakpointUp.lg} {
    .gatsby-image-wrapper {
      height: ${({ size }) => (size === 'sm' ? '3rem' : '3.5rem')} !important;
    }
  }

  ${({ isSimpleImage, size }) => {
    if (isSimpleImage) {
      return css`
        img {
          height: ${size === 'sm' ? '1.5rem' : '2rem'};
          width: auto !important;
        }
        @media ${breakpointUp.md} {
          margin-bottom: 0;
          justify-content: end;
          img {
            height: ${size === 'sm' ? '2rem' : '2.5rem'};
          }
        }
        @media ${breakpointUp.lg} {
          img {
            height: ${size === 'sm' ? '3rem' : '3.5rem'};
          }
        }
      `;
    }
  }}
`;

export const HeaderTitle = styled.h1`
  white-space: break-spaces;
  display: block;
  ${textSizes.xl};
  margin: 0;
  color: var(--fg1, ${({ theme }) => theme.fg});
  letter-spacing: -0.03em;

  @media ${breakpointUp.sm} {
    ${textSizes.xxl};
    letter-spacing: -0.02em;
  }

  @media ${breakpointUp.lg} {
    ${textSizes.xxxl};
    letter-spacing: -0.03em;
  }
`;

export const HeaderFooter = styled.footer`
  grid-column: span 2;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 1.5rem 0;
  gap: 1.5rem;

  @media ${breakpointUp.sm} {
    grid-column: span 12;
    padding: 2rem 0;
  }

  @media ${breakpointUp.md} {
    padding: 1.5rem 0;
  }
`;

export const DesktopSocials = styled(SocialIcons)`
  display: none;

  @media ${SHARE_MENU_BREAKPOINT} {
    display: flex;
  }

  @media ${breakpointUp.sm} {
    grid-column: 9 / -1;
  }

  @media ${breakpointUp.md} {
    grid-column: 10 / -1;
  }

  @media ${breakpointUp.lg} {
    grid-column: 11 / -1;
  }
`;

export const MobileSocialsButton = styled.button`
  color: var(--fg1, ${({ theme }) => theme.fg});
  appearance: none;
  display: flex;
  align-items: center;
  border: 0;
  background: none;
  padding: 0.5rem;
  line-height: 1rem;
  column-gap: 0.5rem;
  white-space: nowrap;
  font-size: 0.875rem;
  justify-self: end;

  @media ${SHARE_MENU_BREAKPOINT} {
    display: none;
  }
`;

export const StyledSectorsList = styled(Sectors)`
  color: var(--fg1, ${({ theme }) => theme.fg});
  ${textSizes.xxs};

  ${ListItem} {
    border-bottom: 0.125rem solid rgba(255, 255, 255, 0.15);
  }

  @media ${breakpointUp.sm} {
    grid-column: span 6;
  }
`;
