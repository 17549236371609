import React from 'react';
import SbEditable from 'storyblok-react';
import CardBlogContent, { CardBlogContentProps } from '.';
import { BlokComponent, StoryblokAsset } from '../../../types/blok.model';
import { useBlogPostThumbnails } from '../../../util/useBlogPostThumbnails';
import { useAuthors } from '../../../old/storyblok/authors-context';

export interface CardBlogContentBlokProps extends CardBlogContentProps {
  contentId: string;
  logo: StoryblokAsset;
}

const CardBlogContentBlok: BlokComponent<CardBlogContentBlokProps> = ({
  blok,
}) => {
  const post = useBlogPostThumbnails([blok.contentId])?.[0];
  const authors = useAuthors();
  const author = authors.find((item) => item.uuid === post?.author);

  return (
    <SbEditable content={blok}>
      {post && (
        <CardBlogContent
          {...blok}
          title={post.title}
          link={post.link}
          author={author}
          thumbnailAlt={post.thumbnail_alt}
          mobile={{
            aspect: '3 / 4',
            thumbnail: post.thumbnail_3x4,
          }}
          tablet={{
            aspect: '4 / 3',
            thumbnail: post.thumbnail_4x3,
          }}
          desktop={{
            aspect: '4 / 3',
            thumbnail: post.thumbnail_4x3,
          }}
        />
      )}
    </SbEditable>
  );
};

export default CardBlogContentBlok;
