import { CaseStudyThumbnailProps } from '../../blocks/CaseStudyThumbnail';
import React, { FC } from 'react';
import styled from 'styled-components';
import CMSLink from '../../components/CMSLink';
import { textAlt, textSizes } from '../../../styles/typography';
import { breakpointUp } from '../../../styles/mediaUtils';
import Sectors from '../../components/Sectors';
import { ResponsiveImage } from '../../components/ResponsiveImage';

export const ThumbnailWrapper = styled.div`
  grid-column: span 6;
  display: block;
  width: 100%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ImgContainer = styled(ResponsiveImage)`
  width: 100%;
  margin-bottom: 1.5rem;
  aspect-ratio: 3 / 4;

  & > div {
    height: 100%;
  }

  @media ${breakpointUp.md} {
    aspect-ratio: 4 / 3;
  }
`;

export const ClientName = styled.div`
  ${textSizes.xxs};
  ${textAlt};
  color: ${({ theme }) => theme.fg};
`;

export const LinkWrapper = styled(CMSLink)`
  cursor: pointer;
  height: 100%;
  width: 100%;
`;

export const Title = styled.h3`
  ${textSizes.sm};
  color: ${({ theme }) => theme.fg};
  margin: 0.25rem 0 0.5rem;

  @media ${breakpointUp.sm} {
    ${textSizes.md}
  }

  @media ${breakpointUp.lg} {
    ${textSizes.lg}
  }
`;

export const TagsSection = styled(Sectors)`
  color: ${({ theme }) => theme.grey};

  li {
    ${textSizes.xxs};
    border-bottom: 0.125rem solid rgba(151, 151, 151, 0.15);

    :hover {
      border-bottom: 0.125rem solid rgba(151, 151, 151, 0.15);
    }
  }
`;

export const CaseStudyRelatedPost: FC<CaseStudyThumbnailProps> = ({
  link,
  thumbnail_alt,
  client,
  title,
  categories,
  tags,
  thumbnail_sm,
  thumbnail_lg,
}) => {
  return (
    <ThumbnailWrapper>
      <LinkWrapper to={link}>
        <ImgContainer
          alt={thumbnail_alt}
          mobile={thumbnail_sm}
          tablet={thumbnail_lg}
          desktop={thumbnail_lg}
        />
        <ClientName aria-label="client">{client}</ClientName>
        <Title>{title}</Title>
      </LinkWrapper>
      <TagsSection tags={tags} asLinks categories={categories} />
    </ThumbnailWrapper>
  );
};
