import { IGatsbyImageData } from 'gatsby-plugin-image';
import React, { FC, useMemo } from 'react';
import {
  Link,
  Image,
  Card,
  Title,
  Author,
  AuthorDetails,
  AuthorAvatar,
  AuthorName,
  Description,
} from './CardBlogContent.styles';
import { AuthorType } from '../../../old/storyblok/authors-context';
import { TagType } from '../../../old/storyblok/tags-context';
import TagsBlock from '../TagsBlock';

export type CardBlogContentProps = {
  link?: string;
  thumbnailAlt?: string;
  mobile?: {
    aspect?: string;
    thumbnail?: string | IGatsbyImageData;
  };
  tablet?: {
    aspect?: string;
    thumbnail?: string | IGatsbyImageData;
  };
  desktop?: {
    aspect?: string;
    thumbnail?: string | IGatsbyImageData;
  };
  author?: AuthorType;
  title?: string;
  tags?: TagType[];
  description?: string;
};

const CardBlogContent: FC<CardBlogContentProps> = ({
  link,
  thumbnailAlt,
  mobile,
  tablet,
  desktop,
  title,
  author,
  tags,
  description,
}) => {
  const trimmedAuthor = useMemo(
    () => ({
      ...author,
      name: author?.name,
      description: author?.description.split(' at Elsewhen')?.[0],
    }),
    [author]
  );

  return (
    <Card>
      <Link to={link ?? ''}>
        {thumbnailAlt &&
          mobile?.thumbnail &&
          tablet?.thumbnail &&
          desktop?.thumbnail && (
            <Image
              alt={thumbnailAlt}
              mobile={mobile.thumbnail}
              tablet={tablet.thumbnail}
              desktop={desktop.thumbnail}
              $aspectMobile={mobile.aspect}
              $aspectTablet={tablet.aspect}
              $aspectDesktop={desktop.aspect}
            />
          )}
        {!description && title && <Title>{title}</Title>}
        {author && (
          <Author>
            <AuthorAvatar
              mobile={author.avatar}
              tablet={author.avatar}
              desktop={author.avatar}
              alt={author.name}
              loading="eager"
            />
            <AuthorDetails>
              <AuthorName>{trimmedAuthor?.name}</AuthorName>
            </AuthorDetails>
          </Author>
        )}
        {description && title && <Title>{title}</Title>}
        {description && <Description>{description}</Description>}
      </Link>
      {tags && <TagsBlock tags={tags} />}
    </Card>
  );
};

export default CardBlogContent;
