import styled from 'styled-components';
import { breakpointUp } from '../../../styles/mediaUtils';
import { textSizes } from '../../../styles/typography';

export const StyledWrapper = styled.div`
  color: ${({ theme }) => theme.greyMedium};
  ${textSizes.xs};
  padding-bottom: 5.5rem;
  margin-top: 2rem;

  @media ${breakpointUp.sm} {
    ${textSizes.md}
    margin-top: 3rem;
  }

  @media ${breakpointUp.lg} {
    margin-top: 4rem;
  }

  & > p {
    ${textSizes.xs}
    display: inline-block;
    margin: 0 0 1.5rem;
    width: 100%;

    @media ${breakpointUp.sm} {
      ${textSizes.sm}
      margin: 0 0 2rem;
    }

    @media ${breakpointUp.md} {
      ${textSizes.md}
      margin: 0 0 3rem;
    }
  }

  picture + p {
    ${textSizes.xs};
  }

  ul > li > p {
    margin: 0;
  }

  picture > img {
    display: block;
    max-width: 100%;
    max-height: 80vh;
  }

  & > p:nth-of-type(1) {
    color: ${({ theme }) => theme.black};
    ${textSizes.sm};

    @media ${breakpointUp.sm} {
      ${textSizes.md}
    }

    @media ${breakpointUp.md} {
      ${textSizes.lg}
    }
  }

  p:last-child {
    margin: 0;
  }

  h2 {
    ${textSizes.md};
    margin: 0.5rem 0 1.5rem;
    color: ${({ theme }) => theme.black};

    @media ${breakpointUp.sm} {
      ${textSizes.lg}
      margin: 0 0 2.25rem;
    }

    @media ${breakpointUp.md} {
      ${textSizes.xl}
    }
  }

  h3 {
    ${textSizes.sm};
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.black};

    @media ${breakpointUp.sm} {
      ${textSizes.md}
    }

    @media ${breakpointUp.md} {
      ${textSizes.lg}
    }
  }

  h4 {
    ${textSizes.xs};
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.black};

    @media ${breakpointUp.sm} {
      ${textSizes.sm}
    }

    @media ${breakpointUp.md} {
      ${textSizes.md}
    }
  }

  ol,
  ul {
    margin: 0 0 1.5rem;

    @media ${breakpointUp.sm} {
      ${textSizes.sm};
      margin: 0 0 2rem;
    }

    @media ${breakpointUp.md} {
      ${textSizes.md};
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  ol {
    padding: 0 0 0 2.5rem;
  }

  li {
    margin: 0 0 1rem;
    padding: 0 0 0 0.5rem;

    @media ${breakpointUp.md} {
      margin: 0 0 1.25rem;
    }
  }

  ul > li {
    display: flex;
  }

  ul > li::before {
    content: '-';
    margin-right: 1.4375rem;
    transform: translateX(0.3125rem);
    color: ${({ theme }) => theme.cloud};
  }

  a[href] {
    text-decoration: underline;
  }

  blockquote {
    margin: 1.5rem 0;

    @media ${breakpointUp.md} {
      margin: 2.5rem 0;
    }
  }

  dl {
    margin: 2rem 0;

    @media ${breakpointUp.md} {
      margin: 3rem 0;
    }
  }
`;
