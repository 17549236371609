import styled from 'styled-components';
import { TextFeatureModuleProps } from '.';
import { breakpointUp } from '../../../styles/mediaUtils';

export const Module = styled.div<TextFeatureModuleProps>`
  grid-column: 1 / -1;

  @media ${breakpointUp.tablet} {
    grid-column: ${({ rightAlign }) => (rightAlign ? `6 / span 6` : `span 6`)};
  }

  @media ${breakpointUp.desktop} {
    grid-column: ${({ rightAlign }) => (rightAlign ? `7 / span 5` : `span 5`)};
  }

  @media ${breakpointUp.desktopLarge} {
    grid-column: ${({ rightAlign }) => (rightAlign ? `7 / span 5` : `span 5`)};
  }

  &.title {
    @media ${breakpointUp.tablet} {
      grid-column: ${({ rightAlign }) =>
        rightAlign ? `3 / span 9` : `span 9`};
    }

    @media ${breakpointUp.desktop} {
      grid-column: ${({ rightAlign }) =>
        rightAlign ? `5 / span 7` : `span 7`};
    }

    @media ${breakpointUp.desktopLarge} {
      grid-column: ${({ rightAlign }) =>
        rightAlign ? `7 / span 5` : `span 5`};
    }
  }
`;
