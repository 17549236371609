import React from 'react';
import SbEditable, { SbEditableContent } from 'storyblok-react';
import {
  BlokComponent,
  NewStoryblokMetaData,
  StoryblokRelatedPosts,
} from '../../types/blok.model';
import RenderAfterPageTransition from '../../old/components/RenderAfterPageTransition';
import styled, { ThemeProvider } from 'styled-components';
import defaultTheme, { darkTheme } from '../../styles/themeVariables';

import InlineFilterModule from '../modules/InlineFilterModule';
import CardList from '../modules/CardList';
import CardCaseStudy from '../blocks/CardCaseStudy';
import { Layout } from '../layout/Layout';
import { Grid } from '../layout/Grid';
import { Section } from '../layout/Section';
import { Pagination } from '../components/Pagination/Pagination';

import { spacing } from '../../styles/newThemeVariables.styles';
import { useCaseStudyThumbnails } from '../../util/useCaseStudyThumbnails';
import { useCaseStudies } from '../../old/storyblok/caseStudies-context';
import { useViewport } from '../../util/useViewport';
import transformCollection from '../../util/transformCollection';

interface CaseStudyCollectionPageSchema extends NewStoryblokMetaData {
  headerTitle: string;
  featured_posts: StoryblokRelatedPosts;
  body?: SbEditableContent[];
  bodyLower?: SbEditableContent[];
}

const Background = styled.div`
  background: ${({ theme }) => theme.bgPrimary};
`;

const ListContainer = styled.div`
  margin-bottom: ${spacing.space112};
`;

const CaseStudyCollectionPage: BlokComponent<CaseStudyCollectionPageSchema> = ({
  blok,
  Dynamic,
}) => {
  // handle pagination
  const { pagination, posts } = useCaseStudies();
  const postUuids = posts?.map((post) => post.uuid);
  const convertedCaseStudies = useCaseStudyThumbnails(postUuids);
  const transformedPosts =
    convertedCaseStudies &&
    transformCollection(convertedCaseStudies, blok.featured_posts);
  const { isMobile } = useViewport();

  return (
    <SbEditable content={blok}>
      <ThemeProvider theme={defaultTheme}>
        <Background>
          <RenderAfterPageTransition>
            <InlineFilterModule
              headerTitle={blok.headerTitle}
              resetLabel="All case studies"
              resetFilterUrl="work/"
            />

            {transformedPosts && (
              <>
                <ListContainer>
                  <CardList
                    scrollMobile={false}
                    mobile={{
                      rowGap: spacing.space64,
                      variant: 'one-column',
                    }}
                    tablet={{
                      rowGap: spacing.space64,
                      variant: 'one-column',
                    }}
                    desktop={{
                      rowGap: spacing.space112,
                      variant: 'two-column',
                    }}
                    desktopLarge={{
                      rowGap: spacing.space112,
                      variant: 'two-column',
                    }}
                  >
                    {transformedPosts.map((caseStudy) => (
                      <CardCaseStudy
                        key={caseStudy.id}
                        title={caseStudy.title}
                        link={caseStudy.link}
                        clientLogo={caseStudy.logo}
                        clientLogoDark={caseStudy.logoDark}
                        clientName={caseStudy.client}
                        clientLogoAlt={caseStudy.client}
                        mobile={{
                          aspect: '3 / 4',
                          thumbnail: caseStudy.thumbnail_3x4,
                        }}
                        tablet={{
                          aspect: '16 / 9',
                          thumbnail: caseStudy.thumbnail_16x9,
                        }}
                        desktop={{
                          aspect: '4 / 3',
                          thumbnail: caseStudy.thumbnail_4x3,
                        }}
                        desktopLarge={{
                          aspect: '4 / 3',
                          thumbnail: caseStudy.thumbnail_4x3,
                        }}
                        thumbnailAlt={caseStudy.thumbnail_alt}
                        tags={[...caseStudy.tags, ...caseStudy.categories]}
                      />
                    ))}
                  </CardList>
                </ListContainer>

                {pagination?.currentPage && pagination?.numPages && (
                  <Section>
                    <Layout>
                      <Grid>
                        <Pagination
                          siblings={{
                            left: isMobile ? 1 : 2,
                            right: isMobile ? 1 : 1,
                          }}
                          currentPage={pagination?.currentPage}
                          totalNumberOfPages={pagination?.numPages}
                          hrefBuilder={(page) => {
                            if (page === 1) {
                              return '/work/';
                            }

                            return `/work/${page}`;
                          }}
                        />
                      </Grid>
                    </Layout>
                  </Section>
                )}
              </>
            )}

            {blok.body?.map((insideBlok) => (
              <Dynamic key={insideBlok._uid} blok={insideBlok} />
            ))}
          </RenderAfterPageTransition>
        </Background>
      </ThemeProvider>
      <ThemeProvider theme={darkTheme(defaultTheme)}>
        <Background>
          <RenderAfterPageTransition>
            {blok.bodyLower?.map((insideBlok) => (
              <Dynamic key={insideBlok._uid} blok={insideBlok} />
            ))}
          </RenderAfterPageTransition>
        </Background>
      </ThemeProvider>
    </SbEditable>
  );
};

export default React.memo(CaseStudyCollectionPage);
