import React from 'react';
import ReportThumbnail, {
  ReportThumbnailProps,
} from '../../blocks/ReportThumbnail';
import styled, { ThemeProvider } from 'styled-components';
import { darkTheme } from '../../../styles/themeVariables';
import isEmpty from '../../../util/isEmpty';
import { textSizes } from '../../../styles/typography';
import { Layout } from '../../layout/Layout';
import { Grid } from '../../layout/Grid';
import LinkButton from '../../components/Button/LinkButton';

export type ReportRelatedPostsProps = {
  posts: ReportThumbnailProps[];
};

const StylSection = styled.div`
  background: ${({ theme }) => theme.bgPrimary};
  padding: 3rem 0;
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0 1.5rem 0;
  align-items: flex-end;
`;

const Title = styled.h2`
  ${textSizes.xxs};
  font-weight: 700;
  letter-spacing: 0.15em;
  color: var(--fg2, ${({ theme }) => theme.fg});
  margin-bottom: 1.5rem;
`;

const RelatedPostsGrid = styled(Grid)`
  row-gap: 3rem;
`;

const StyledGridItem = styled.div`
  grid-column: span 6;
`;

export const ReportRelatedPosts = ({ posts }: ReportRelatedPostsProps) => {
  return (
    <ThemeProvider theme={darkTheme}>
      <StylSection>
        <Layout>
          <TitleRow>
            <Title>RELATED REPORTS</Title>
            <LinkButton
              btnsize={'sm'}
              variant={'ghost'}
              dark={true}
              href={`/reports/`}
            >
              See more work
            </LinkButton>
          </TitleRow>
          <RelatedPostsGrid>
            {!isEmpty(posts) &&
              posts.map((item) => {
                return (
                  <StyledGridItem key={item.id}>
                    <ReportThumbnail
                      {...item}
                      light_theme={false}
                      thumbnail_bg_sm={item.thumbnail_bg_sm}
                      thumbnail_bg_lg={item.thumbnail_bg_lg}
                    />
                  </StyledGridItem>
                );
              })}
          </RelatedPostsGrid>
        </Layout>
      </StylSection>
    </ThemeProvider>
  );
};

export default ReportRelatedPosts;
