import React from 'react';
import { Section } from '../../layout/Section';
import { SpacerElement } from './Spacer.styles';

type Sizes = 'xs' | 'sm' | 'md' | 'lg';

export interface SpacerProps {
  mobileUp?: Sizes;
  tabletUp?: Sizes;
  desktopUp?: Sizes;
}

const Spacer: React.FC<SpacerProps> = (props) => {
  return (
    <Section>
      <SpacerElement {...props} />
    </Section>
  );
};

export default Spacer;
