import styled from 'styled-components';
import { typeScale } from '../../../styles/newTypography';
import { breakpointUp } from '../../../styles/mediaUtils';
import { spacing } from '../../../styles/newThemeVariables.styles';

export const Body = styled.div`
  color: ${({ theme }) => theme.text400};
  ${typeScale.mobile.body.xs}
  margin-bottom: ${spacing.space24};

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.body.sm}
    margin-bottom: ${spacing.space24};
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.body.md}
    margin-bottom: ${spacing.space40};
  }

  @media ${breakpointUp.desktopLarge} {
    ${typeScale.desktop.body.lg}
    margin-bottom: ${spacing.space40};
  }
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.textPrimary};

  ${typeScale.mobile.body.lg}
  margin-bottom: ${spacing.space24};

  @media ${breakpointUp.tablet} {
    ${typeScale.tablet.body.lg}
    margin-bottom: ${spacing.space24};
  }

  @media ${breakpointUp.desktop} {
    ${typeScale.desktop.body.xxl}
    margin-bottom: ${spacing.space32};
  }

  @media ${breakpointUp.desktopLarge} {
    ${typeScale.desktop.body.xxxl}
    margin-bottom: ${spacing.space32};
  }

  .title & {
    ${typeScale.mobile.body.xxl}
    margin-bottom: ${spacing.space24};

    @media ${breakpointUp.tablet} {
      ${typeScale.desktop.body.xxl}
      margin-bottom: ${spacing.space24};
    }

    @media ${breakpointUp.desktop} {
      ${typeScale.desktop.body.xxxl}
      margin-bottom: ${spacing.space24};
    }

    @media ${breakpointUp.desktopLarge} {
      ${typeScale.desktop.body.xxxxl}
      margin-top: ${spacing.space16};
      margin-bottom: ${spacing.space32};
    }
  }
`;

export const Container = styled.div``;
